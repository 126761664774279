<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:13:45
 * @LastEditTime: 2022-07-18 15:27:55
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\App.vue
-->
<template>
<div >
    <MobileMenu class="lg:hidden" />
    <TopBar class="hidden lg:block" />
    <div class="flex lg:hidden" style="min-height:600px">
        <main class="w-full overflow-hidden ">
            <router-view></router-view>
        </main>
    </div>
    
    <div class="hidden w-3/4 lg:block" style="max-width:1200px;margin-left:auto;margin-right:auto;" >
        <div style="display:flex;min-height:650px" v-if="!isShowCode">
            <SlideBar />
            <main class="w-full overflow-hidden ">
                <div>
                    <div class="flex items-center pl-4 pr-4 text-sm text-gray-400 flex-nowrap h-11 ">
                        <span  @click="searchFuncNext()"  class="text-green-700 cursor-pointer">首页</span>

                        <span  v-if="rootName=='搜索'">
                            <span class="pl-2 pr-2 text-gray-400" >/</span>
                            {{rootName}}
                        </span>
                        <span  @click="slideQueClick()"   class="text-green-700 cursor-pointer " v-if="rootName!='搜索' && rootName && !rootDevId">
                            <span class="pl-2 pr-2 text-gray-400" >/</span>
                            {{rootName}}
                        </span>
                        <span  @click="slideQueClickDev()"   class="text-green-700 cursor-pointer " v-if="rootName!='搜索' && rootDevId">
                            <span class="pl-2 pr-2 text-gray-400" >/</span>
                            {{rootName}}
                        </span>
                        <span   v-show="this.$route.path=='/article'" > <span class="pl-2 pr-2 text-gray-400" v-show="this.$route.path=='/article'" >/</span>详情 </span>
                    </div>
                </div>
                <router-view></router-view>
                <Pop />
            </main>
        </div>
        <div v-else  class="w-full overflow-hidden ">
                <router-view></router-view>
        </div>
    </div>
    <Footer />
</div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import {useRouter} from 'vue-router'
import { useStore } from 'vuex' 
import { getDeviceTypeList } from "@/api/devicetype.js";
import MobileMenu from "@/components/MobileMenu.vue";
import TopBar from "@/components/TopBar.vue";
import SlideBar from "@/components/SlideBar.vue";
import Pop from "@/components/devPop.vue";
import Footer from "@/components/footer.vue";
import { searchArticleList } from "@/api/article.js";
export default {
    name: 'App',
    components: {
        MobileMenu,
        TopBar,Footer,
        SlideBar,Pop
    },
    data() {
        return {
            keepTabs: []
        }
    },
    setup(props) {
        const router = useRouter();
        onMounted(() => {
            // getArtListData()
            getDevListData() 
            
        });
        const store = useStore() 
        
        const isShowCode = computed(() => store.state.isShow);
        const rootName = computed(() => store.state.rootName); 
        const rootId = computed(() => store.state.rootId); 
        const rootDevId = computed(() => store.state.rootDevId); 
        const getDevListData = () => {
            getDeviceTypeList().then(res => {
                store.commit('SET_DEV', res.data)
            })
        }
        const searchFuncNext=()=> {
            var params = {}
            params.BelongType = ''
            params.CategoryId =''
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
            params.PageSize = '15'
            params.SearchKeyWord = ''
            searchArticleList(params).then(res => {
                console.log('searchFuncNext');                
                store.commit('SET_LIST', res.data.data) 
                store.commit('SET_ROOTNAME','')
                store.commit('SET_ROOTID','')
                store.commit('SET_ROOTDEVID', '')
                 const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                console.log('11111',arr);
                store.commit('SET_PAGE', arr)
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
            })
        }  
        const slideQueClick = () => { 
            var params = {}
            params.BelongType = ''
            params.CategoryId = rootId.value
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                console.log('slideQueClick');
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME',rootName.value)
                store.commit('SET_ROOTID', rootId.value)
                store.commit('SET_ROOTDEVID', '')
                 const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                store.commit('SET_PAGE', arr)
            }) 
        }  
        const slideQueClickDev = () => { 
            var params = {}
            params.BelongType = ''
            params.CategoryId = ''
            params.SortType = ''
            params.ConnectDeviceType = rootDevId.value
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                console.log('slideQueClickDev');
                
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME',rootName.value)
                store.commit('SET_ROOTID', '')
                store.commit('SET_ROOTDEVID', rootDevId.value)
               const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                console.log('33333',arr);
                store.commit('SET_PAGE', arr)


            }) 
        }  
        return {isShowCode, getDevListData,rootDevId, store, rootName,rootId,searchFuncNext ,slideQueClick,slideQueClickDev} 
    }
}
</script>

<style lang="less">
#nprogress .bar {
    background-color: #42b983 !important;
}
.searchText{
    color:red;
    font-weight: bold;
}
</style>
