/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:13:45
 * @LastEditTime: 2022-07-18 11:39:38
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\store\index.js
 */
import { createStore } from 'vuex'
export default createStore({
  state: {
    slideMenuShow:false,
    feedbackShow:false,
    topMenuShow:false,
    rootName:'',
    rootId:'',
    rootDevId:'',
    topMenuId:0,
    ArtListData:[], 
    ArtListDatas:[], 
    devListData:[],
    slideMenu:[],
    page:{pageIndex:'',pageSize:'',totalCount:''},
    keyWorld:'',
    isShow:false
  },
  mutations: {
    TOGGLE_SIDEBAR:(state, device) => {
      state.slideMenuShow = device
    },
    TOGGLE_BTBAR:(state, device) => {
      state.feedbackShow = device
    },
    TOGGLE_TOPBAR:(state, device) => {
      state.topMenuShow = device
    },
    SET_KEYWORLD:(state, device) => {
      state.keyWorld = device
    },
    SET_PAGE:(state, device) => {
      state.page = device
    },
    SET_MENU:(state, device) => {
      state.slideMenu = device
    },
    SET_LIST:(state, device) => {
      state.ArtListData = device
    },
    SET_LISTS:(state, device) => {
      state.ArtListDatas = device
    },
    SET_DEV:(state, device) => {
      state.devListData = device
    },
    SET_MENUID:(state, device) => {
      state.topMenuId = device
    },
    SET_ROOTNAME:(state, device) => {
      state.rootName = device
    },
    SET_ROOTID:(state, device) => {
      state.rootId = device
    },
    SET_ROOTDEVID:(state, device) => {
      state.rootDevId = device
    },
    SET_INTRO:(state, device) => {
      state.isShow = device
    },
  },
  actions: { 
  },
  modules: {
  }
})
