/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-03 17:08:15
 * @LastEditTime: 2021-09-09 11:47:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qa\src\components\api\api.js
 */
import request from '@/http';
//列表查询
export const searchArticleList = (params) => {
  return request({
    url:`/api/Article/Query`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//获取详情
export const searchArticleId = (params) => {
  return request({
    url:`/api/Article`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//新增
export const addArticle = (params) => {
  return request({
    url:`/api/Article`,
    method: 'POST',
    params: {
      ...params,
    }
  })  
}
//更新
export const updateArticle = (params) => {
  return request({
    url:`/api/Article`,
    method: 'PUT',
    params: {
      ...params,
    }
  })  
}
//删除
export const deleteArticle = (params) => {
  return request({
    url:`/api/Article`,
    method: 'DELETE',
    params: {
      ...params,
    }
  })  
}

