/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-03 17:30:19
 * @LastEditTime: 2021-10-09 15:47:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qa\src\components\api\axios.js
 */
/*
* 全站http配置
* axios参数说明
* isSerialize是否开启form表单提交
* isToken是否需要token
*/
import axios from 'axios';
//import {Message} from 'element-ui';
//import {Base64} from 'js-base64';
// import { Notify } from "vant";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
//默认超时时间
axios.defaults.timeout = 10000;
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;
// NProgress 配置
NProgress.configure({
  showSpinner: false
});
//http request拦截
axios.interceptors.request.use(config => {
  //开启 progress bar
  NProgress.start();
  const meta = (config.meta || {});
 // config.headers['Authorization'] = `Basic ${Base64.encode(`${website.clientId}:${website.clientSecret}`)}`;
  //headers中配置text请求
  if (config.text === true) {
    config.headers["Content-Type"] = "text/plain";
  }
  //headers中配置serialize为true开启序列化
  // if (config.method === 'post' && meta.isSerialize === true) {
  //   config.data = serialize(config.data);
  // }
  return config
}, error => {
  return Promise.reject(error)
});
//http response 拦截
axios.interceptors.response.use(res => { 
  //关闭 progress bar
  NProgress.done();
  //获取状态码
  const status = res.data.code || res.status;
  const errorMsg = res.data.desc ||  '未知错误'; 
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    Notify(errorMsg);
    return Promise.reject(new Error(errorMsg))
  } 
  return res;
}, error => {
  NProgress.done();
  return Promise.reject(new Error(error));
});
export default axios;
