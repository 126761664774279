<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-12-07 16:53:52
 * @LastEditors: ZHONGHUIDI
 * @LastEditTime: 2022-07-18 11:45:32
 * @FilePath: \NvcSmartFAQ\QAPreject\src\components\devPop.vue
-->
<template>
    <div :class="devPop ?'fixed top-0 left-0 w-full h-full' :'' "  @click="devPop=false,scanPop=false"   >
        <div class="fixed flex flex-col pt-6 pb-6 pl-2 pr-2 text-center rounded-t-full rounded-b-full right-10 top-2/3 bs" >
            <div class="w-full text-center cursor-pointer" @click="sbToggle()"   @mouseover="devPop=true,scanPop=false"  >
                <img src="../assets/shebei.png"  class="w-8 " />
            </div>
            <div class="w-full mt-5 text-center cursor-pointer"   @mouseover="scanPop=true,devPop=false"    @mouseout="scanPop=false,devPop=false" >
                <img src="../assets/erweima.png" class="w-8 " />
            </div> 
        </div>
        <div  class="fixed p-3 mt-12 text-center bg-white top-2/3 right-24 bs"  v-show="scanPop"  >
                <img src="../assets/scan.png"  class="w-32 "  />
                <span class="pb-2 text-sm">下载APP</span>
        </div>
        <div class="fixed p-3 mt-40 bg-white top-1/3 right-24 bs"    v-show="devPop" >
        <!-- {{devListData}} -->
          <div class="mb-3" v-for="item in devListData" :key="item.id">
              <div class="mb-1 text-base cursor-pointer hover:text-green-700" @click="devIdClick(item)">{{item.name}}</div>
              <span class="text-sm text-gray-700 "  v-for="child in item.children" :key="child.id">
                  <span class="pr-3 cursor-pointer hover:text-green-700"  @click="devIdClick(child)">{{child.name}}</span>
              </span>
          </div>  
        </div>

    </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'
import { searchArticleList } from "@/api/article.js";
import { getDeviceAllTypeList } from "@/api/devicetype.js";
export default {
    setup () {
        const store = useStore() 
        const router = useRouter();
        const devListData = computed(() => store.state.devListData);  
        const scanPop=ref(false)
        const devPop=ref(false)
        const devIdClick=(item)=>{
            devIdClick1(item.id)
            var params = {}
            params.BelongType = ''
            params.CategoryId =''
            params.SortType = ''
            params.ConnectDeviceType =  item.id
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                devPop.value=false
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME', item.name)
                store.commit('SET_ROOTID', '')
                store.commit('SET_ROOTDEVID', item.id)
                const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                store.commit('SET_PAGE', arr)
            })
        }
        
        const devIdClick1=(item)=>{
            var params = {}
            params.id =  item
            params.SearchKeyWord =''
            getDeviceAllTypeList(params).then(res => { 
                store.commit('SET_LISTS', res.data.data)
            })
        }
        const sbToggle=()=>{
            scanPop.value=false
            devPop.value=!devPop.value
        }
        return {
            devListData,devPop,scanPop,devIdClick,sbToggle,devIdClick1
        }
    }
}
</script>

<style  scoped>
.bs{
    box-shadow: 0px 0px 20px #ccc;
}
</style>