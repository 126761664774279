/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-03 17:08:15
 * @LastEditTime: 2021-09-09 11:47:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qa\src\components\api\api.js
 */
import request from '@/http';
//列表查询
export const getCategoryList = (params) => {
  return request({
    url:`/api/Category/Query`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//获取单个
export const getCategorySingle = (params) => {
  return request({
    url:`/api/Category`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//获取单个 子等级
export const getCategoryAll = (params) => {
  return request({
    url:`/api/Category/QueryLevels`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//新增
export const addCategory = (params) => {
  return request({
    url:`/api/Category`,
    method: 'POST',
    params: {
      ...params,
    }
  })  
}
//更新
export const updateCategory = (params) => {
  return request({
    url:`/api/Category`,
    method: 'PUT',
    params: {
      ...params,
    }
  })  
}
//删除
export const deleteCategory = (params) => {
  return request({
    url:`/api/Category`,
    method: 'DELETE',
    params: {
      ...params,
    }
  })  
}

