<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-10-11 08:21:10
 * @LastEditTime: 2022-07-19 09:22:10
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \QAPreject\src\components\TopBar.vue
--> 
<template>
<div class="z-30 w-full">
<div class="fixed top-0 left-0 w-full bg-white " style='z-index: 11;'>
    <!-- logo -->
    <div class="pl-20 pr-20 bg-gray-100">  
        <div class="z-30 flex items-center justify-between h-11 ">
            <div @click="backMainPage" class="cursor-pointer ">
                <img src="../assets/logo.png" width="106" height="24" />                
            </div>
            <div class="text-sm cursor-pointer">
                <!-- <span class="mr-10 cursor-pointer" @click="goIntro" >下载客户端</span> -->
                 客服热线：<span class="text-green-700 ">400 894 0606</span>  </div>
        </div>
    </div>
    <!-- 标题 -->
    <div class="z-30 flex items-center justify-between h-16 pl-20 pr-20 text-xl border-b border-gray-200 ">
        帮助中心
    </div>  
</div>
<!-- 搜索 -->
<div class="flex justify-center" style="max-width: 900px;margin:0 auto ;" v-if="!isShowCode">
    <div class="flex justify-center w-full mt-44 h-72 ">
        <div class="w-full bg-no-repeat bg-cover" style="background-image: url('https://static1.tuyacn.com/static/developer-help/_next/static/img/a5856bf21759b78427c72ce9741381b5.png')">
           <div class="">
               <div class="w-full mt-20 text-4xl text-center text-green-700">雷士智能照明帮助中心</div>
               <div class="flex items-center w-3/4 h-12 mt-12 overflow-hidden bg-white border-2 rounded-full border-grey-100" style="margin-left:auto ;margin-right:auto">
                <svg t="1632968266913" class="w-8 ml-2 icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6881" ><path d="M426.666667 227.555556a199.111111 199.111111 0 1 1-140.8 58.311111A199.111111 199.111111 0 0 1 426.666667 227.555556m0-85.333334a284.444444 284.444444 0 1 0 201.102222 83.342222A284.444444 284.444444 0 0 0 426.666667 142.222222z" p-id="6882" fill="#D8DADB"></path><path d="M839.111111 881.777778a42.951111 42.951111 0 0 1-30.151111-12.515556l-227.555556-227.555555a42.666667 42.666667 0 0 1 60.302223-60.302223l227.555555 227.555556a42.382222 42.382222 0 0 1 0 60.302222 42.951111 42.951111 0 0 1-30.151111 12.515556z" p-id="6883" fill="#D8DADB"></path></svg>
                <form @submit.prevent="onSubmit=()=>{return false;}" class="w-full">
                    <input type="search"  v-model="SearchKeyWord"  @keyup.enter='searchFuncNext' class="w-full p-3 text-sm bg-white outline-none " placeholder="请输入搜索内容">
                </form>
            </div>
           </div>
        </div> 
    </div>
</div>

</div>
</template>

<script>
import { computed, toRefs, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'
import { searchArticleList } from "@/api/article.js";
export default {
    name: 'TopBar',
    components: {
    },
    setup() { 
        const router = useRouter();
        const store = useStore()
        const data = reactive({ 
            SearchKeyWord: '',
        }) 
        
        const isShowCode = computed(() => store.state.isShow);
        const searchFuncNext=()=> {
            var params = {}
            params.BelongType = ''
            params.CategoryId =''
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord = data.SearchKeyWord 
            searchArticleList(params).then(res => {
                console.log('searchFuncNext');
                
                store.commit('SET_INTRO',false)
                router.push({ path: "/" });
                store.commit('SET_LIST', res.data.data)
                const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                console.log('topbar',arr);
                store.commit('SET_PAGE', arr)
                if(data.SearchKeyWord){
                    store.commit('SET_ROOTNAME','搜索')
                    store.commit('SET_ROOTID','')
                    store.commit('SET_KEYWORLD',data.SearchKeyWord)
                    store.commit('SET_ROOTDEVID', '')
                }
            })
        } 
        const backMainPage=()=>{ 
            data.SearchKeyWord=''
            router.push({ path: "/" });
            store.commit('SET_ROOTNAME','')
            store.commit('SET_ROOTID','')
            store.commit('SET_ROOTDEVID', '')
            store.commit('SET_INTRO',false)
            searchFuncNext()
        }
        const goIntro=()=>{
            router.push({ path: "/introduce" });
            store.commit('SET_INTRO',true)
        }
        
        return {
          ...toRefs(data), searchFuncNext,backMainPage,goIntro,isShowCode
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>

