<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-10-13 09:14:14
 * @LastEditTime: 2021-12-29 16:13:48
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\components\footer.vue
-->
<template>
    <div class="flex justify-center w-full h-10 mt-10 text-xs leading-10 text-center ">
        <a target="__blank" href="http://beian.miit.gov.cn/" class="text-green-700 ">粤ICP备19088095号</a>
        <span> ©{{year}}
        </span>
        <span class="pl-2 pr-2"> 惠州雷士光电科技有限公司</span>  
        <span class="hidden pr-2 text-black lg:block ">|</span>
        <span class='hidden lg:block' > <a target="__blank" href="https://support.nvc-smart.com/admin/"  class="cursor-pointer hover:text-green-700" > 后台管理</a></span> 
    </div>
</template>
<script>
export default {
    setup () {
        const year=new Date().getFullYear();
        return {
            year
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
