<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:58:43
 * @LastEditTime: 2021-12-03 15:47:39
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\components\SlideMenu.vue
-->
<template>
    <div class="sm:w-20 xl:w-64"> 
        <div class="fixed top-0 z-20 w-full h-full bg-black opacity-30 bg-light-blue-600 "  @click="slideQueClick(0)" >
        </div>
        <div class="fixed top-0 z-20 w-3/4 h-full overflow-scroll bg-white">
           <div v-for="item in sideMenuData"  :key="item.id"  >
                <div  @click="item.children.length==0?slideQueClick(item):''"  class="flex justify-between w-full h-full p-1 pl-4 text-sm leading-10 text-black border-b opacity-100 " >
                  <span  @click="slideQueClick(item)"  :class="rootName==item.name?'text-green-700':''" >{{item.name}}</span>
                  <span v-if="item.children.length!=0"    @click="item.isShow=!item.isShow" >
                      <svg t="1634519929666" :class="[item.isShow?'transform rotate-90':'','w-4 h-full mr-3  icon']" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5128" ><path d="M357.376 852.31a25.6 25.6 0 0 0 36.181 36.18l358.4-358.4a25.6 25.6 0 0 0 0-36.18l-358.4-358.4a25.6 25.6 0 0 0-36.181 36.18L697.686 512l-340.31 340.31z" p-id="5129" fill="#008754"></path></svg>
                  </span>
                </div>
                <div v-show="item.isShow">
                  <div  v-for="itemsub in item.children"  :key="itemsub.id"  @click="slideQueClick(itemsub)" class="w-full h-full p-1 pl-8 text-sm leading-10 text-black border-b opacity-100 "                  >
                        <span   :class="rootName==itemsub.name?'text-green-700':''">
                            {{itemsub.name}}
                        </span>
                  </div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref,onMounted,computed  } from 'vue'
import bus from 'vue3-eventbus'
import {useRouter} from 'vue-router'
import { useStore} from 'vuex'
import { getCategoryAll, } from "@/api/category.js";
export default {
  name: 'SlideMenu',
    setup() {
      onMounted(() => {
        getsideMenuData()
      }); 
      const  sideMenuData =ref(); 
      const getsideMenuData = ()=>{ 
          getCategoryAll().then(res => {
              sideMenuData.value=res.data.data
          })
      }
      const router = useRouter();
      const store = useStore()  
        const rootName = computed(() => store.state.rootName);
      const slideQueClick = (item)=>{ 
        if(item==0){
          store.commit('TOGGLE_SIDEBAR',!store.state.slideMenuShow)
        }else{
          store.commit('SET_ROOTNAME',item.name)
          store.commit('SET_ROOTID',item.id)
          bus.emit('slideQueClickSun', { id:item.id })
          router.push({ path: "/" ,query:{id:item.id}});
                store.commit('SET_INTRO',false)
        }
      }
      return {sideMenuData,getsideMenuData,slideQueClick,rootName}
      }
}
</script>

<style lang="scss" scoped>

</style>