<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:13:45
 * @LastEditTime: 2022-07-18 11:45:18
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\components\MobileMenu.vue
-->
<template>
    <nav>
        <!-- 顶部logo -->
        <div class="z-10 flex items-center justify-between pl-4 pr-4 h-11 ">
            <div @click="goHome">
                <img src="../assets/logo.png" width="106" height="24" />                
            </div>
            <div> 
                <svg @click="toggleBtBar" t="1632356002150"  class=" icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2716" width="24" height="24"><path d="M512 518.542222a28.444444 28.444444 0 0 1-28.444444-28.444444v-46.648889a28.444444 28.444444 0 0 1 28.444444-28.444445 36.977778 36.977778 0 1 0-36.693333-36.977777 28.444444 28.444444 0 0 1-56.888889 0A93.582222 93.582222 0 1 1 540.444444 467.342222v22.755556a28.444444 28.444444 0 0 1-28.444444 28.444444z" fill="#3F4042" p-id="2717"></path><path d="M483.555556 540.444444m28.444444 0l0 0q28.444444 0 28.444444 28.444445l0 0q0 28.444444-28.444444 28.444444l0 0q-28.444444 0-28.444444-28.444444l0 0q0-28.444444 28.444444-28.444445Z" fill="#3F4042" p-id="2718"></path><path d="M286.72 881.777778a42.666667 42.666667 0 0 1-42.666667-42.666667V739.555556A102.684444 102.684444 0 0 1 142.222222 636.302222V245.475556A102.684444 102.684444 0 0 1 244.337778 142.222222h535.324444A102.684444 102.684444 0 0 1 881.777778 245.475556v390.826666A102.684444 102.684444 0 0 1 779.662222 739.555556h-256l-214.755555 135.68a44.657778 44.657778 0 0 1-22.186667 6.542222zM244.337778 227.555556a17.351111 17.351111 0 0 0-16.782222 17.92v390.826666a17.351111 17.351111 0 0 0 16.782222 17.92h42.382222a42.666667 42.666667 0 0 1 42.666667 42.666667v64.853333l159.857777-100.977778A43.235556 43.235556 0 0 1 512 654.222222h267.662222a17.351111 17.351111 0 0 0 16.782222-17.92V245.475556a17.351111 17.351111 0 0 0-16.782222-17.92z" p-id="2719" fill="#3F4042"></path></svg>
            </div>
        </div>
        <!-- 搜索栏bg-gray-50 --> 
        <div class="flex items-center pl-4 pr-4 h-11 ">
            <div class="flex pr-3" @click="toggleSlideMenu">
                <svg t="1632355904330" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2482" width="24" height="24"><path d="M895.431111 284.444444h-512a42.666667 42.666667 0 0 1 0-85.333333h512a42.666667 42.666667 0 0 1 0 85.333333zM895.431111 554.666667h-512a42.666667 42.666667 0 0 1 0-85.333334h512a42.666667 42.666667 0 0 1 0 85.333334zM211.342222 284.444444H127.431111a42.666667 42.666667 0 0 1 0-85.333333h83.911111a42.666667 42.666667 0 0 1 0 85.333333zM212.764444 554.666667H129.137778a42.666667 42.666667 0 1 1 0-85.333334h83.626666a42.666667 42.666667 0 0 1 0 85.333334zM212.764444 824.888889H129.137778a42.666667 42.666667 0 1 1 0-85.333333h83.626666a42.666667 42.666667 0 1 1 0 85.333333zM895.431111 824.888889h-512a42.666667 42.666667 0 0 1 0-85.333333h512a42.666667 42.666667 0 0 1 0 85.333333z" p-id="2483" fill="#3F4042"></path></svg>
            </div>
            <div class="flex items-center w-full pl-3 mr-3 overflow-hidden rounded-full bg-gray-50 h-9">
                <svg t="1632968266913" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6881" width="16" height="16"><path d="M426.666667 227.555556a199.111111 199.111111 0 1 1-140.8 58.311111A199.111111 199.111111 0 0 1 426.666667 227.555556m0-85.333334a284.444444 284.444444 0 1 0 201.102222 83.342222A284.444444 284.444444 0 0 0 426.666667 142.222222z" p-id="6882" fill="#D8DADB"></path><path d="M839.111111 881.777778a42.951111 42.951111 0 0 1-30.151111-12.515556l-227.555556-227.555555a42.666667 42.666667 0 0 1 60.302223-60.302223l227.555555 227.555556a42.382222 42.382222 0 0 1 0 60.302222 42.951111 42.951111 0 0 1-30.151111 12.515556z" p-id="6883" fill="#D8DADB"></path></svg>
                <form @submit.prevent="onSubmit=()=>{return false;}" class="flex-grow">
                    <input type="search"  v-model="SearchKeyWord"  @keyup.enter='searchFuncNext' class="w-full p-2 text-sm outline-none bg-gray-50 " placeholder="请输入搜索内容">
                </form>
                
            </div>
            <div class="flex">
                <svg @click="toggleTopBar" t="1632356041297" class="icon " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2922" width="24" height="24"><path d="M369.777778 227.555556a28.444444 28.444444 0 0 1 28.444444 28.444444v142.222222H227.555556V256a28.444444 28.444444 0 0 1 28.444444-28.444444h113.777778m0-85.333334H256a113.777778 113.777778 0 0 0-113.777778 113.777778v227.555556h341.333334V256a113.777778 113.777778 0 0 0-113.777778-113.777778zM398.222222 654.222222v113.777778a28.444444 28.444444 0 0 1-28.444444 28.444444H256a28.444444 28.444444 0 0 1-28.444444-28.444444v-113.777778h170.666666m85.333334-85.333333H142.222222v199.111111a113.777778 113.777778 0 0 0 113.777778 113.777778h113.777778a113.777778 113.777778 0 0 0 113.777778-113.777778v-199.111111zM783.36 227.555556a13.368889 13.368889 0 0 1 13.084444 13.084444V398.222222h-170.666666V240.64a13.368889 13.368889 0 0 1 13.084444-13.084444h144.497778m0-85.333334h-144.497778A98.417778 98.417778 0 0 0 540.444444 240.64V483.555556h341.333334V240.64A98.417778 98.417778 0 0 0 783.36 142.222222zM853.333333 753.777778H568.888889a28.444444 28.444444 0 0 1 0-56.888889h284.444444a28.444444 28.444444 0 0 1 0 56.888889zM739.555556 881.777778h-170.666667a28.444444 28.444444 0 0 1 0-56.888889h170.666667a28.444444 28.444444 0 0 1 0 56.888889zM853.333333 625.777778H568.888889a28.444444 28.444444 0 0 1 0-56.888889h284.444444a28.444444 28.444444 0 0 1 0 56.888889z" p-id="2923" fill="#3F4042"></path></svg>
            </div>
        </div>
        <!-- 路由 -->
        <div class="flex items-center pl-4 pr-4 text-sm text-gray-400 flex-nowrap h-11 ">
            <span  @click="goHome()"  class="pr-2 text-green-700 cursor-pointer">首页</span>
            <span  v-if="rootName=='搜索'"><span class="pr-2 text-gray-400" >/</span>{{rootName}}</span>
            <span  v-else-if="rootDevId" @click="slideDEVClick()"   class="text-green-700 cursor-pointer" ><span class="pr-2 text-gray-400" >/</span>{{rootName}}</span> 
            <span  v-else-if="rootName!='搜索' && rootName"  @click="slideQueClick()"   class="text-green-700 cursor-pointer"><span class="pr-2 text-gray-400" >/</span>{{rootName}}</span>  
            <span  v-show="this.$route.path=='/article'" ><span class="pl-2 pr-2 text-gray-400" >/</span>详情 </span>
        </div>
        <div class="flex items-center w-full h-3 bg-gray-100 "></div>
       
        <!-- 反馈 -->
        <div  v-show="feedbackShow" >
            <div class="fixed top-0 z-20 w-full h-full bg-black opacity-30 bg-light-blue-600 " @click="toggleBtBar">
            </div>
            <div class="fixed bottom-0 z-20 w-full text-center bg-white h-28 rounded-t-xl">
                <div class="mt-5 text-base text-green-600 hover:text-green-900" @click="jumpApp">
                    <!-- <a href="nvc://feedback">前往APP反馈问题</a> -->
                    前往APP反馈问题
                </div>
                <div class="mt-4 text-base text-gray-600">
                    拨打客服热线：<a href="tel:400 894 0606">400 894 0606</a>
                </div>

            </div>
        </div>
        <!-- 顶部菜单 -->
        <div  v-show="topMenuShow"  class="w-full h-full overflow-hidden">
            <div class="fixed top-0 z-20 w-full h-full overflow-hidden bg-black opacity-30 bg-light-blue-600"  @click="toggleTopBar">
            </div> 
            <div class="fixed z-20 flex w-full mt-2 overflow-scroll bg-white flex-nowrap top-20 h-80 rounded-b-2xl">
                <!-- 左边  -->
                <div class="fixed flex flex-col text-base text-gray-600 bg-gray-100 w-28 ">
                    <div v-for="(item,index) in devListData" :key="index">
                        <div :class="['p-3 border-l-4 ml-1 border-gray-100',index==topMenuId ? 'bg-white border-l-4 border-green-600 ':'' ]" @click="changeMenuId(index)">{{item.name}}</div> 
                    </div>
                </div>
                <!-- 右边 -->
                <div  class="flex flex-col w-full mr-4 text-sm ml-28 ">
                    <div class="flex flex-wrap" v-if="devListData.length!=0">
                        <div @click="devIdClick(item)" v-for="item in devListData[topMenuId].children" :key="item.id" class="grid content-center w-1/2 h-36 justify-items-center">
                            <img :src="item.imgPath" alt="" class="w-10 h-10 mb-2">
                            <div >{{item.name}}</div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SlideMenu class="lg:hidden" v-show="slideMenuShow" />
    </nav>
</template>

<script>
import { computed, toRefs, reactive, onMounted,nextTick } from 'vue'
import bus from 'vue3-eventbus'
import { useStore } from 'vuex'
import {useRouter} from 'vue-router'
import SlideMenu from "@/components/SlideMenu.vue";
import { searchArticleList } from "@/api/article.js";
import { getDeviceAllTypeList } from "@/api/devicetype.js";

export default {
    name: 'MobileMenu',
    components: {
        SlideMenu
    },
    setup() {
        
        onMounted(() => {
            searchFunc()
        });
        const store = useStore()
        const router = useRouter();
        const slideMenuShow = computed(() => store.state.slideMenuShow);
        const toggleSlideMenu = () => {
            store.commit('TOGGLE_SIDEBAR', !store.state.slideMenuShow)
            store.commit('TOGGLE_TOPBAR', false)
        }
        const feedbackShow = computed(() => store.state.feedbackShow);
        const toggleBtBar = () => {
            store.commit('TOGGLE_BTBAR', !store.state.feedbackShow)
            store.commit('TOGGLE_TOPBAR', false)
        }
        const topMenuId = computed(() => store.state.topMenuId);
        const devListData = computed(() => store.state.devListData); 
        const topMenuShow = computed(() => store.state.topMenuShow);
        const rootName = computed(() => store.state.rootName);
        const rootId = computed(() => store.state.rootId);
        const rootDevId = computed(() => store.state.rootDevId);
        const toggleTopBar = () => {store.commit('TOGGLE_TOPBAR', !store.state.topMenuShow)        }
        const data = reactive({
            BelongType: '',
            CategoryId: '',
            SortType: '',
            ConnectDeviceType: '',
            SearchKeyWord: '',
            otherData:[]
        })
        const searchFunc = () => {
            var params = {}
            params.BelongType = ''
            params.CategoryId = data.CategoryId
            params.SortType = ''
            params.ConnectDeviceType = data.ConnectDeviceType
            params.PageIndex = ''
            params.PageSize = '15'
            params.SearchKeyWord = ''
            searchArticleList(params).then(res => {
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_LISTS','')
                 const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                store.commit('SET_PAGE', arr)

            })
        }
        const searchFuncNext=()=> {
            var params = {}
            params.BelongType = ''
            params.CategoryId =''
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord = data.SearchKeyWord 
            searchArticleList(params).then(res => {
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_LISTS','')
                store.commit('SET_ROOTID','')
                store.commit('SET_ROOTDEVID','')
        //   store.commit('TOGGLE_SIDEBAR',false)
                if(data.SearchKeyWord){
                    store.commit('SET_ROOTNAME','搜索')
                    store.commit('SET_ROOTID','')
                    store.commit('SET_ROOTDEVID', '')
                }
            })
        }
        const devIdClick=(item)=>{
            devIdClick1(item.id)
            var params = {}
            params.BelongType = ''
            params.CategoryId =''
            params.SortType = ''
            params.ConnectDeviceType =  item.id
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME', item.name)
                store.commit('SET_ROOTID', '')
                store.commit('SET_ROOTDEVID', item.id)
                 const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                store.commit('SET_PAGE', arr)
            })
        }
        const devIdClick1=(item)=>{
            var params = {}
            params.id =  item
            params.SearchKeyWord =''
            getDeviceAllTypeList(params).then(res => { 
                store.commit('SET_LISTS', res.data.data)
            })
        }
        const slideQueClick = () => { 
            var params = {}
            params.BelongType = ''
            params.CategoryId = rootId.value
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME',rootName.value)
                store.commit('SET_ROOTID', rootId.value)
                store.commit('SET_ROOTDEVID', '')
            }) 
        }
        const slideDEVClick = () => { 
            var params = {}
            params.BelongType = ''
            params.CategoryId = ''
            params.SortType = ''
            params.ConnectDeviceType =rootDevId.value
            params.PageIndex = ''
             params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME',rootName.value)
                store.commit('SET_ROOTDEVID', rootDevId.value)
                store.commit('SET_ROOTID', '')
            }) 
        }
        
        bus.on('slideQueClickSun', (val) => {
            data.CategoryId = val.id
            
          store.commit('TOGGLE_SIDEBAR',false)
            searchFunc()
        })
        // 修改设备id
        const changeMenuId=(id)=>{
            store.commit('SET_MENUID',id)
        }
        const goHome=()=>{ 
            data.SearchKeyWord=''
            router.push({ path: "/" });
                store.commit('SET_INTRO',false)
            store.commit('SET_ROOTNAME','')
            searchFuncNext()
        }
        // app跳转
        const jumpApp=()=>{
            var u = navigator.userAgent;
            var isWeixin = u.toLowerCase().indexOf('micromessenger') !== -1; // 微信内
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            var loadDateTime = new Date();
            if(isAndroid){
                android(loadDateTime);
            }
            if(isiOS){
                android(loadDateTime);
            }
        }
        const android=(loadDateTime)=>{
             //ios的scheme协议
            window.location.href = 'nvc://feedback'
            setTimeout(() => {
                var timeOutDateTime = new Date();
                if (timeOutDateTime - loadDateTime < 3000) {
                    window.location = "https://app.nvc-smart.com/d/nvchome"; //ios下载地址  
                } else {
                    window.close();
                }
            }, 2000); 
        } 
         
        return {
            store,...toRefs(data),  searchFunc, searchFuncNext,devIdClick,goHome,
            toggleSlideMenu,slideMenuShow,
            toggleBtBar,feedbackShow,rootName,rootDevId,rootId,
            toggleTopBar,topMenuShow,devListData,topMenuId,changeMenuId,slideQueClick,slideDEVClick,jumpApp,android
        }
    }
}
</script>
