<!--
 * @Author: ZHONGHUIDI
 * @Date: 2021-10-12 09:14:06
 * @LastEditTime: 2022-07-18 11:45:17
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\components\SlideBar.vue
-->
<template>
    <div >
        <div class="mr-6 bg-white border-r-2 w-60">
            <div v-for="item in slideMenu" :key="item.id">
                <div  @click="item.children.length==0?slideQueClick(item):''"     class="flex justify-between w-full h-full p-1 pl-4 text-sm leading-10 text-black opacity-100 cursor-pointer hover:text-green-700" >
                    <span  @click="slideQueClick(item)"   :class="rootName==item.name ||rootId==item.id  ?'text-green-700':''" >{{item.name}}</span>
                    <span   @click="item.isShow=!item.isShow" v-if="item.children.length!=0" >
                        <svg t="1634519929666" :class="[item.isShow?'transform rotate-90':'','w-4 h-full mr-3  cursor-pointer  icon']" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5128" ><path d="M357.376 852.31a25.6 25.6 0 0 0 36.181 36.18l358.4-358.4a25.6 25.6 0 0 0 0-36.18l-358.4-358.4a25.6 25.6 0 0 0-36.181 36.18L697.686 512l-340.31 340.31z" p-id="5129" fill="#008754"></path></svg>
                    </span>
                </div>
                <div v-show="item.isShow" > 
                    <div  v-for="itemsub in item.children"  :key="itemsub.id" @click="slideQueClick(itemsub)" class="w-full h-full p-1 pl-8 text-sm leading-10 text-black opacity-100 cursor-pointer hover:text-green-700 " >
                        <span   :class="rootName==itemsub.name||rootId==itemsub.id ?'text-green-700':''">
                            {{itemsub.name}}
                        </span>
                    </div> 
                </div>
            </div> 
        </div>
    </div>
</template>

<script>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getCategoryAll, } from "@/api/category.js";
import { searchArticleList } from "@/api/article.js";
export default {
    name: 'SlideBar',
    setup() {
        onMounted(() => {
            getsideMenuData()
        });
        const store = useStore()
        const getsideMenuData = () => {
            getCategoryAll().then(res => {
                store.commit('SET_MENU', res.data.data)
            })
        }
        const router = useRouter();
        const slideMenu = computed(() => store.state.slideMenu);
        const rootName = computed(() => store.state.rootName);
        const rootId = computed(() => store.state.rootId);
        const slideQueClick = (item) => {
             var params = {}
            params.BelongType = ''
            params.CategoryId = item.id
            params.SortType = ''
            params.ConnectDeviceType = ''
            params.PageIndex = ''
            params.PageSize = '15'
            params.SearchKeyWord =''
            searchArticleList(params).then(res => {
                router.push({ path: "/" });
                store.commit('SET_INTRO',false)
                store.commit('TOGGLE_TOPBAR', false)
                store.commit('SET_LIST', res.data.data)
                store.commit('SET_ROOTNAME', item.name)
                store.commit('SET_ROOTID', item.id)
                store.commit('SET_ROOTDEVID', '') 
                const arr={
                    pageIndex:res.data.pageIndex,
                    pageSize:res.data.pageSize,
                    totalCount:res.data.totalCount,
                }
                console.log('slide',arr);
                store.commit('SET_PAGE', arr)
            }) 
        }
        return { slideMenu, getsideMenuData, rootName,rootId, slideQueClick }
    }
}
</script>

<style lang="scss" scoped>

</style>