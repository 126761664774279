/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-03 17:08:15
 * @LastEditTime: 2021-11-23 16:37:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \qa\src\components\api\api.js
 */
import request from '@/http';

//查附件
export const getDeviceAllTypeList = (params) => {
  return request({
    url:`/api/DeviceType/QueryConnectVideoAndAttach`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//列表查询
export const getDeviceTypeList = (params) => {
  return request({
    url:`/api/DeviceType/Query`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//获取单个
export const getDeviceTypeSingle = (params) => {
  return request({
    url:`/api/DeviceType`,
    method: 'get',
    params: {
      ...params,
    }
  })  
}
//新增
export const addDeviceType = (params) => {
  return request({
    url:`/api/DeviceType/Add`,
    method: 'POST',
    params: {
      ...params,
    }
  })  
}
//更新
export const updateDeviceType = (params) => {
  return request({
    url:`/api/DeviceType`,
    method: 'PUT',
    params: {
      ...params,
    }
  })  
}
//删除
export const deleteDeviceType = (params) => {
  return request({
    url:`/api/DeviceType`,
    method: 'DELETE',
    params: {
      ...params,
    }
  })  
}

