/*
 * @Author: ZHONGHUIDI
 * @Date: 2021-09-30 08:13:45
 * @LastEditTime: 2022-07-18 11:37:47
 * @LastEditors: ZHONGHUIDI
 * @Description: In User Settings Edit
 * @FilePath: \NvcSmartFAQ\QAPreject\src\router\index.js
 */
import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'), 
  },{
    path: '/article',
    name: 'article',
    component: () => import('../views/article.vue')
  },{
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/introduce.vue')
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
